<template>
    <section class="scientific-partnership">
        <Nav
            class="mt-5 mb-4"
            route-name="Partnership"
            parent-page-name="Партнерство"
            current-page-name="Научное партнерство"
        />

        <div class="scientific-partnership__main-img d-none d-xl-flex">
            <div class="scientific-partnership__blurred-img mt-lg-auto ms-lg-auto d-flex flex-column">
                <h2 class="scientific-partnership__title">{{ getScientificPartnershipPageInfo('title') }}</h2>
                <div class="scientific-partnership__text mt-3">
                    {{ getScientificPartnershipPageInfo('text') }}
                </div>
                <img
                    src="../../assets/page-logo.svg"
                    class="scientific-partnership__logo ms-auto mt-lg-auto d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="educational-partnership__title educational-partnership__title--dark">
                {{ getScientificPartnershipPageInfo('title') }}
            </h2>
            <div class="educational-partnership__text educational-partnership__text--dark">
                {{ getScientificPartnershipPageInfo('text') }}
            </div>
        </div>

        <div class="perspectives d-flex flex-column flex-lg-row py-5">
            <div class="col-12 col-lg-8 col-xl-7 col-xxl-6">
                <img
                    src="../../assets/views/scientific_partnership/image.png"
                    alt=""
                    class="perspectives__img"
                >
            </div>

            <div class="col-12 col-lg-4 col-xl-5 col-xxl-6 me-5 mt-4">
                <h3 class="perspectives__title">{{ getScientificPartnershipPageInfo('subtitle_1') }}</h3>
                <div class="perspectives__text mt-3">
                    {{ getScientificPartnershipPageInfo('subtext_1') }}
                </div>
            </div>
        </div>

        <div class="other-opportunities mt-5">
            <div class="col-12 col-md-10 col-lg-8 col-xl-6">
                <div class="other-opportunities__title">{{ getScientificPartnershipPageInfo('subtitle_2') }}</div>
                <div class="other-opportunities__text mt-4">
                    {{ getScientificPartnershipPageInfo('subtext_2') }}
                </div>
            </div>

            <div class="d-flex flex-column flex-lg-row mt-5">
                <div class="other-opportunities-card other-opportunities-card--resources w-100 p-4">
                    <div class="other-opportunities-card__title d-flex align-items-center">
                        <img width="64" src="../../assets/views/scientific_partnership/resources.png" alt="">
                        <div class="ms-3">{{ getWidgetInfo(8, 'title') }}</div>
                    </div>
                    <div class="other-opportunities-card__text mt-3">
                        {{ getWidgetInfo(8, 'text') }}
                    </div>
                </div>

                <div class="other-opportunities-card other-opportunities-card--projects w-100 ms-lg-4 p-4 mt-3 mt-lg-0">
                    <div class="other-opportunities-card__title d-flex align-items-center">
                        <img width="64" src="../../assets/views/scientific_partnership/projects.png" alt="">
                        <div class="ms-3">{{ getWidgetInfo(9, 'title') }}</div>
                    </div>
                    <div class="other-opportunities-card__text mt-3">
                        {{ getWidgetInfo(9, 'text') }}
                    </div>
                </div>

                <div class="other-opportunities-card other-opportunities-card--innovative w-100 ms-lg-4 p-4 mt-3 mt-lg-0">
                    <div class="other-opportunities-card__title d-flex align-items-center">
                        <img width="64" src="../../assets/views/scientific_partnership/innovative.png" alt="">
                        <div class="ms-3">{{ getWidgetInfo(10, 'title') }}</div>
                    </div>
                    <div class="other-opportunities-card__text mt-3">
                        {{ getWidgetInfo(10, 'text') }}
                    </div>
                </div>
            </div>

            <div
                class="strategic-partnership__opportunities mt-5 d-flex flex-column flex-lg-row align-items-center py-5 py-lg-0">
                <div class="col-12 col-lg-6 d-flex justify-content-center">
                    <div class="opportunities__card d-flex justify-content-center">
                        <img
                            src="../../assets/views/strategic_partnership/opportunities.svg"
                            alt=""
                            class="opportunities__img mx-4"
                        >
                    </div>
                </div>

                <div class="col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
<!--                    <div class="opportunities__title mx-3">{{ getScientificPartnershipPageInfo('subtitle_3') }}</div>-->
                    <div class="opportunities__text mt-3 mx-3">
                        {{ getScientificPartnershipPageInfo('subtext_3') }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "ScientificPartnership",

    components: {
        Nav
    },

    setup() {
        const store = useStore()
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getScientificPartnershipPage')
        const scientificPartnershipPage = computed(() => store.getters['pages/scientificPartnershipPage'])

        const getScientificPartnershipPageInfo = (key) => {
            if (scientificPartnershipPage.value) {
                return scientificPartnershipPage.value[key]
            }

            return ''
        }

        const getWidgetInfo = (id, key) => {
            if (scientificPartnershipPage.value) {
                return scientificPartnershipPage.value.widget.items.find(item => item.id === id)[key]
            }

            return ''
        }

        return {
            getScientificPartnershipPageInfo,
            getWidgetInfo
        }
    }
}
</script>
